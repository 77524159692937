import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";
import { loadError } from "../../../Static/images/icons";

const NotFound = () => {
  return (
    <div className="vtmn-w-full vtmn-text-2xl vtmn-text-center vtmn-mt-5">
      {loadError}
      <div className="vtmn-w-full vtmn-p-5 vtmn-text-center ">
        <FormattedMessage id="pdi.page-not-found" />
      </div>
      <button
        onClick={() => {
          window.location.replace("/pdi");
        }}
        className="vp-button"
        type="button"
      >
        <FormattedMessage id="pdi.nav.go-home" />
      </button>
    </div>
  );
};
export default NotFound;
