import React from "react";
import { FormattedMessage } from "react-intl";
import { somethingWentWrong } from "../../../Static/images/icons";
// import { useRouteError } from "react-router-dom";

const ErrorBoundary = () => {
  // const error = useRouteError();
  return (
    <div className="vtmn-w-full vtmn-text-2xl vtmn-text-center vtmn-mt-5">
      {somethingWentWrong}
      <div className="vtmn-w-full vtmn-p-5 vtmn-text-center ">
        Something went wrong
        {/* <small>{error?.message}</small> */}
      </div>
      <button
        onClick={() => {
          window.location.replace("/pdi");
        }}
        className="vp-button"
        type="button"
      >
        <FormattedMessage id="pdi.nav.go-home" />
      </button>
    </div>
  );
};

export default ErrorBoundary;
