import { FormattedMessage } from "react-intl";
import { getOrderInfo } from "../../Common/APIs/getOrderInfo";
import { loader, somethingWentWrong } from "../../Static/images/icons";

export const extractOrderItems = async (id) => {
  const { id: reference_id = id, item_information = [] } =
    (await getOrderInfo(id)) || {};
  const items = item_information.flat();
  const filtered_items = [];
  for (let i = 0; i < items.length; i++) {
    const filtered_sku = items[i]?.skus.find(
      (sku) => sku?.skuId === items[i]?.representativeSku
    );
    filtered_items.push(filtered_sku);
  }
  return { reference_id, filtered_items };
};

export const getKeyByValue = (obj, value) => {
  for (const key of Object.keys(obj)) {
    if (obj[key] === value) {
      return key;
    }
  }
  return undefined; // Return undefined if the value is not found
};
export const isAlphanumeric = (str) => {
  // Regular expression to ensure the string contains both letters and numbers
  const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
  return regex.test(str);
};
export const handleLoading = () => {
  return (
    <div className="vtmn-w-full vtmn-text-center vtmn-text-2xl vtmn-mt-5">
      {loader}
      <div className="vtmn-w-full vtmn-p-5 vtmn-text-center ">
        <FormattedMessage id="pdi.loading" />
      </div>
    </div>
  );
};

export const handleError = () => {
  return (
    <div className="vtmn-w-full vtmn-text-center vtmn-text-2xl vtmn-mt-5">
      {somethingWentWrong}
      <div className="vtmn-w-full vtmn-p-5 vtmn-text-center ">
        <FormattedMessage id="pdi.error-loading" />
      </div>
      <button
        onClick={() => {
          window.location.replace("/pdi");
        }}
        className="vp-button"
        type="button"
      >
        <FormattedMessage id="pdi.nav.go-home" />
      </button>
    </div>
  );
};

export const handleQuestionFormatError = () => {
  return (
    <div className="vtmn-w-full vtmn-text-center">
      Error - Unknown Question Format
    </div>
  );
};

export const HandleNoAccess = () => {
  return (
    <div className="vtmn-w-full vtmn-text-center vtmn-text-2xl vtmn-mt-5">
      {somethingWentWrong}
      <div className="vtmn-w-full vtmn-p-5 vtmn-text-center ">
        <FormattedMessage id="pdi-rental.page-not-accessible" />
      </div>
      <button
        onClick={() => {
          window.location.replace("/pdi");
        }}
        className="vp-button"
        type="button"
      >
        <FormattedMessage id="pdi.nav.go-home" />
      </button>
    </div>
  );
};
