import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import HomeHeader from "./HomeHeader";
import Header from "./Header";

const Layout = ({ children }) => {
  const breakpointValues = {
    sm: 600,
  };
  const [width, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  const isLandingPage = window.location.pathname === "/";
  const isQualityControlHomePage = window.location.pathname === "/pdi";
  const isDesktop = width > breakpointValues.sm;
  return (
    <>
      <div
        className="homepage  vtmn-h-full vtmn-flex vtmn-flex-col vtmn-mx-auto"
        style={isDesktop ? { width: "40%" } : null}
      >
        <div className="vtmn-flex-1">
          {isLandingPage ? (
            <HomeHeader />
          ) : (
            <Header isQualityControlHomePage={isQualityControlHomePage} />
          )}
          <main>{children}</main>
        </div>
        {isLandingPage || isQualityControlHomePage ? <Footer /> : null}
      </div>
    </>
  );
};

export default Layout;
